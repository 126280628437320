@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  padding-top: var(--sectionPaddingSmall);
  padding-bottom: var(--sectionPaddingSmall);

  @include for-mobile-screens {
    padding-top: var(--sectionPaddingMedium);
    padding-bottom: var(--sectionPaddingSmall);
  }

  > div {
    padding: 96px 70px 40px 70px;
    background-color: var(--navy);
    border-radius: 24px;

    @include for-mobile-screens {
      border-radius: 10px;
      padding: 56px 16px 16px;
    }
  }

  .logo {
    @include for-mobile-screens {
      margin-bottom: 32px;
    }
  }

  .header {
    margin-bottom: 32px;
  }

  .portableWrapper {
    margin-bottom: 32px;
  }
  .portableOverrides {
    > p{
      color: var(--white);
    }
  }

  .cta {
    width: fit-content;
  }

  .brands {
    margin-top: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .brandsImage {
    position: relative;
    height: 40px;
    width: 70%
  }
}
